/**
 * @Description 选课秘籍
 * @author shilin deng
 * @date 2020/11/24
 */
import React from 'react'
import SwiperCore, { Pagination, EffectFade, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { HOME_RMP } from '../../../utils/constant'
import { Image } from '../../common'

SwiperCore.use([Pagination, EffectFade, Autoplay])

export default function Rmp() {
  const SwiperOptions = {
    pagination: {
      clickable: true,
    },
    autoplay: true,
    autoplayspeed: 5000,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 1000,
  }

  return (
    <section
      className="o-section o-section--l p-home__rmp"
      id="js-rmp_search"
    >
      <div className="l-container">
        <h2 className="o-section__header--xl u-font-bold u-text-center u-mb-s">
          荃程无忧老师已帮助3,000+留学生选到最适合他们的课程
        </h2>
        <p className="o-section__header--m u-text-center u-mb-l">
          查看学长学姐的教授评分，找到最适合你的教授与课程
        </p>

        <div className="c-slider js-lt-slider">
          <Swiper {...SwiperOptions}>
            {HOME_RMP.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="c-slider__item">
                  <div className="p-home__rmp__slide">
                    <div className="p-home__rmp__line-top">
                      <Image
                        width={595}
                        height={174}
                        alt=""
                        src="/images/pages/rmp/line-1.svg"
                      />
                    </div>
                    <div className="p-home__rmp__tag">
                      <Image
                        alt=""
                        src="/images/pages/rmp/tag.svg"
                        width={268}
                        height={268}
                      />
                    </div>
                    <div className="p-home__rmp__block p-home__rmp__block--left p-home__rmp__block--alt o-block o-block--primary u-mb-m-md">
                      <div className="u-font-size-md-h5 u-font-size-base u-font-bold u-mb-s">
                        {item.name}
                        同学使用反馈
                      </div>
                      <p className="u-m-0 u-font-size-base">{item.quote}</p>
                    </div>
                    <div className="p-home__rmp__block p-home__rmp__block--right">
                      <div className="u-text-center">
                        <div className="p-home__rmp__avatar">
                          <Image
                            width={80}
                            height={80}
                            alt=""
                            className="p-profs__avatar u-m-auto"
                            src={`/images/pages/rmp/${item.bd_img}.png`}
                          />
                        </div>
                        <h3 className="o-section__header--s u-font-bold u-mb-xxs u-mt-xs">
                          {item.bd_name}
                          老师
                        </h3>
                        <div className="p-profs__tag u-mb-s">
                          {item.bd_title}
                        </div>
                      </div>
                      <ul
                        className="u-font-size-base u-list-disc u-pl-m u-m-0"
                        dangerouslySetInnerHTML={{ __html: item.list }}
                      />
                    </div>
                    <div className="u-text-center u-d-block-md u-d-none">
                      <Image
                        width={832}
                        height={480}
                        alt=""
                        className="u-m-auto"
                        src="/images/pages/rmp/report.svg"
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
