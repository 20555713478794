/*
 * @Description: 用户数据
 * @Author: shilin deng
 * @Date: 2021-02-19 17:03:22
 */
/* eslint-disable max-len */
import { Image } from '../../common'

const list = [
  {
    img: 'xqbg.svg',
    num: '6,500+',
    unit: '篇',
    text: '学期报告',
  },
  {
    img: 'xkzd.svg',
    num: '4,300+',
    unit: '篇',
    text: '选课报告',
  },
  {
    img: 'spfd.svg',
    num: '43,600+',
    unit: '小时',
    text: '视频辅导',
  },
  {
    img: 'xxjh.svg',
    num: '6,200+',
    unit: '次',
    text: '学习计划',
  },
  {
    img: 'xswt.svg',
    num: '42,500+',
    unit: '次',
    text: '学术问答',
  },
  {
    img: 'zwrs.svg',
    num: '7,100+',
    unit: '篇',
    text: '作文润色',
  },
  {
    img: 'fwxs.svg',
    num: '4,200+',
    unit: '人',
    text: '服务学生',
  },
  {
    img: 'khmyd.svg',
    num: '99.30%',
    unit: '',
    text: '客户满意度',
  },
]
export default function UserData() {
  return (
    <section className="user_data o-section">
      <div className="l-container">
        <div className="title-w">
          <div className="l-row u-justify-content-center">
            <div className="mb2-m mb1 f42 fw5 f2-xs">
              <span className="green">用户数据</span>
              展示
            </div>
            <p className="p-w mb4-m mb2 u-color-dark f4 f15-xs">
              庞大使用数据的背后凸显荃程无忧服务经验的沉淀与运营机制的成熟，通过不断提升的服务水准和专业的培优体系，越来越多的留学生不仅取得高GPA，并且收获了学术领域以及职业道路上的进阶发展。
            </p>
          </div>
        </div>
        <div className="user_data_box">
          <ul className="l-row u-justify-content-center">
            {list.map((info, i) => (
              <li key={i} className="l-col-md-3 l-col-6">
                <div className="img">
                  <Image
                    src={`/images/pages/index/${info.img}`}
                    alt=""
                    height={48}
                    width={58}
                  />
                </div>
                <div className="mt15 f6-xs f5">
                  <span className="f28 f2-xs fw5">{info.num}</span>
                  &nbsp;
                  {info.unit}
                </div>
                <div className="f6 u-color-dark u-text-center">{info.text}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
