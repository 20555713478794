/*
 * @Description: 优质的导师生态系统
 * @Author: shilin deng
 * @Date: 2021-02-19 20:29:59
 */
/* eslint-disable  no-var */
/* eslint-disable  no-undef */
/* eslint-disable  jsx-a11y/media-has-caption */

// 全球导师
const zyds1 = [
  {
    width: '147px',
    num: '1000+名',
    name: '认证导师',
    en: 'Registered educators',
  },
  {
    width: '111px',
    num: '76.00%',
    name: '3年以上教学经验',
    en: 'More than 3 years teaching experience',
  },
  {
    width: '147px',
    num: '86.00%',
    name: '持有硕士或以上学位',
    en: 'Master degree or above',
  },
]
const zyds2 = [
  {
    width: '121px',
    num: '6.42%',
    name: '导师录取率',
    en: 'Employment rate',
  },
  {
    width: '91px',
    num: '39.00%',
    name: '可双语教学',
    en: 'Bilingual instruction',
  },
  {
    width: '121px',
    num: '59.00%',
    name: '毕业于美国Top30，加拿大G13，澳洲G8，英国G5',
    en: 'From Top30 in the United States, G13 in Canada, G8 in Australia, G5 in the United Kindom',
  },
]
const schools = [
  { zh: '剑桥大学', en: 'University of Cambridge' },
  { zh: '牛津大学', en: 'University of Oxford' },
  { zh: '哈佛大学', en: 'Harvard University' },
  { zh: '斯坦福大学', en: 'Stanford University' },
  { zh: '多伦多大学', en: 'University of Toronto' },
  { zh: '悉尼大学', en: 'University of Sydney' },
  { zh: '麻省理工学院', en: 'Massachusetts Institute of Technology' },
  { zh: '伦敦大学学院', en: 'University College London' },
  { zh: '加州理工学院', en: 'California Institute of Technology' },
  { zh: '昆士兰大学', en: 'The University of Queensland' },
  { zh: '华威大学', en: 'The University of Warwick' },
  { zh: '麦吉尔大学', en: 'McGill University' },
  { zh: '澳洲国立大学', en: 'Australian National University' },
  { zh: '英属哥伦比亚大学', en: 'University of British Columbia' },
  { zh: '杜克大学', en: 'Duke University  ' },
  { zh: '耶鲁大学', en: 'Yale University  ' },
  { zh: '墨尔本大学', en: 'University of Melbourne' },
  { zh: '哥伦比亚大学', en: 'Columbia University' },
  { zh: '皇后大学', en: "Queen's University" },
  { zh: '芝加哥大学', en: 'University of Chicago' },
  { zh: '宾夕法尼亚大学', en: 'University of Pennsylvania' },
  { zh: '普林斯顿大学', en: 'Princeton University' },
  { zh: '滑铁卢大学', en: 'University of Waterloo' },
  { zh: '帝国理工学院', en: 'Imperial College London' },
]
export default function Tutor() {
  return (
    <>
      <section className="tutor-pro o-section pb7-m pb4">
        <div className="l-container">
          <div className="title-w">
            <div className="f0 fw6 f3-xs mb2-m mb1">
              全球
              <span className="green">严格筛选</span>
              的专业导师
            </div>
          </div>
          <div className="map l-row">
            <div className="l-col-6 map_pl pt3-l pt0">
              {zyds1.map((info, i) => (
                <div
                  className={`map_box ${info.length === i + 1 ? 'mb0' : ''}`}
                  key={i}
                >
                  <div className="content_r">
                    <div className="f0 green fw5 f2-xs mb15-m mb05">
                      {info.num}
                    </div>
                    <div className="f6-xs f5">{info.name}</div>
                    <div className="en u-d-block-md u-d-none">{info.en}</div>
                  </div>
                  <div
                    className="border u-d-block-md u-d-none"
                    style={{ width: info.width }}
                  />
                </div>
              ))}
            </div>

            <div className="l-col-6 pt3-l pt0">
              {zyds2.map((info, i) => (
                <div
                  className={`map_box map_plr ${i === 2 ? 'mb0' : ''}`}
                  key={i}
                >
                  <div className="border_box">
                    <div
                      className={`u-d-block-md u-d-none ${
                        info.name === '可双语教学' ? 'pl' : ''
                      }`}
                    >
                      <div
                        className="border borderr"
                        style={{ width: info.width }}
                      />
                    </div>
                  </div>
                  <div className="content_l">
                    <div className="f0 green fw5 f2-xs mb15-m mb05">
                      {info.num}
                    </div>
                    <div className="f6-xs f5">{info.name}</div>
                    <div className="en u-d-block-md u-d-none">{info.en}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="title-w mt3">
            <div className="f0 fw6-m f3-xs fw5 mb4-m mb1 mt4-l mt0">
              荃程无忧
              <span className="green">世界院校</span>
              导师分布列表
            </div>
          </div>
          <div className="l-row">
            {schools.map((info, i) => (
              <div className="sc l-col-6 l-col-md-2  mb2" key={i}>
                <div className="name_zh">{info.zh}</div>
                <div className="name_en">{info.en}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
