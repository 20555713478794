/*
 * @Description: 学生成绩
 * @Author: shilin deng
 * @Date: 2021-11-03 15:20:04
 */

export default function StudentScore() {
  return (
    <div className="o-section">
      <div className="l-container">
        <div className="fw5 u-color-dk-dark f42 u-text-center-md f28-xs">
          学生成绩
          <span className="green">捷报</span>
        </div>
        <div className="l-row mt4-m mt15">
          <div className="l-col-md-6 pr4-m center_center">
            <div className="f4 u-color-dark f5-xs">
              荃程无忧的自主版权教研内容体系囊括了丰富多元的教辅资料，涵盖教科书概要和海量题库，并通过系统高效的辅导平台，引导与培养学生获得优异的学业表现。
            </div>
            <div className="l-row mt3-m mt2 u-w-100">
              <div className="l-col-6 mb0-m mb2">
                <div className="u-color-dk-dark fw6 f28 f2-xs">89.71%</div>
                <div className="u-color-dark f5 f6-xs">学生获得B及以上成绩</div>
              </div>
              <div className="l-col-6">
                <div className="u-color-dk-dark fw6 f28">69.12%</div>
                <div className="u-color-dark f5">学生获得A及以上成绩</div>
              </div>
            </div>
          </div>
          <div className="l-col-md-6">
            <img alt="" src="/images/pages/index/student_score.svg" />
          </div>
        </div>
      </div>
    </div>
  )
}
