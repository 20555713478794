/*
 * @Description:
 * @Author: shilin deng
 * @Date: 2021-07-20 15:22:03
 */
/**
 * @Description 合作机构
 * @author shilin deng
 * @date 2020/11/25
 */
import { Image } from '../../common'
import { HOME_PARTNERS } from '../../../utils/constant'

export default function Partners() {
  return (
    <section className="pt7-m pt4">
      <div className="l-container">
        <div className="tc">
          <h2 className="f42 fw5 f2-xs mv0">
            合作机构，
            <span className="green u-m-0">指定</span>
            护航
          </h2>
          <div className="partners_box mb2 l-row">
            {HOME_PARTNERS.map((info, i) => (
              <div key={i} className="l-col-md-3 l-col-6 mt3">
                <a href={info.url} target="_blank" rel="noreferrer">
                  {info.img !== '' && (
                    <Image
                      alt={info.alt}
                      width={info.width}
                      height={info.height}
                      src={`/images/home/${info.img}.png`}
                    />
                  )}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="u-d-block-md u-d-none">
        <div className="l-container u-h-100">
          <div className="l-row u-h-100">
            {/* <div className="l-col-7 flex u-justify-content-center u-align-items-center ">
              <img alt="" src="/images/pages/index/icai_img.png" width={160} />
              <div className="f2 fw5 u-color-dark-dk ml15 lh2">
                <div className="">全球首家获得</div>
                <div className="">
                  <span className="green">国际学术诚信中心（ICAI）</span>
                  认证的在线教育平台
                </div>
              </div>
            </div> */}
            <div className="l-col-12 u-text-center">
              <div className="f5-xs f5 pb1 pr1">
                等逾200家留学服务机构、国际学校及国际项目
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="u-d-none-md">
        <div className="l-container">
          <div className="f6 u-color-dark-dk u-text-center">
            等逾200家留学服务机构、国际学校及国际项目
          </div>
        </div>
        {/* <div className="icai_box_m mt2">
          <div className="l-container">
            <div className="flex u-justify-content-center u-align-items-center">
              <img alt="" src="/images/pages/index/icai_img.png" width={120} />
              <div className="f5 fw5 u-color-dark-dk ml1 lh15">
                <div className="">全球首家获得</div>
                <div className="">
                  <span className="green">国际学术诚信中心（ICAI）</span>
                  认证的在线教育平台
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  )
}
