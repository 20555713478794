/* eslint-disable  camelcase */
/* eslint-disable  */
/*
 * @Description: 首页
 * @Author: shilin deng
 * @Date: 2020-12-30 16:50:45
 */

import {
  Intro,
  UserData,
  Rmp,
  Partners,
  Tutor,
  IP,
  Course,
  Statement,
  CultivateTalents,
  StudentScore,
} from '../components/pages/index'
import { BottomBanner } from '../components/pages/modal'

export default function Home({ user }) {
  return (
    <div className="p-homepage">
      <Intro />
      <CultivateTalents />
      <Tutor />
      <IP />
      <Course />
      <UserData />
      <StudentScore />
      <Rmp />
      <Partners />
      <BottomBanner user={user} />
      {/* <Statement /> */}
    </div>
  )
}
