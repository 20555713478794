/*
 * @Description:
 * @Author: shilin deng
 * @Date: 2021-02-20 14:21:14
 */
const list = [
  { num: '56.00%', text: '新课程覆盖数量同比增长' },
  { num: '2,000', unit: '万页', text: '自主版权内容' },
  { num: '3800,000', unit: '题', text: '考试真题' },
]
export default function IP() {
  return (
    <section className="ip">
      <div className="l-container">
        <div className="l-row">
          <div className="l-col-md-5 flex u-align-items-center">
            <div className="fw5 f0">自主教研IP体系搭建</div>
          </div>
          <div className="l-col">
            <div className="l-row">
              {list.map((v, i) => (
                <div className={`l-col-md-4  ${i === 0 ? 'l-col-6 pr0' : 'l-col'} ${i === 1 ? 'pl2' : ''}`} key={i}>
                  <div className="fw6 f42 mt0-m mt2">
                    {v.num}
                    <span className="fw5 f5">{v.unit}</span>
                  </div>
                  <div className="f5">{v.text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
