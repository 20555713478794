/*
 * @Description:
 * @Author: shilin deng
 * @Date: 2021-02-20 14:58:28
 */
import { Image } from '../../common'

const courseList = [
  {
    icon: 'accounting',
    zh: '会计',
    en: 'Accounting',
  },
  {
    zh: '金融',
    en: 'Financial',
  },
  {
    icon: '',
    zh: '经济学',
    en: 'Economics',
  },
  {
    icon: '',
    zh: '物理',
    en: 'Physics',
  },
  {
    icon: '',
    zh: '数学',
    en: 'Mathematics',
  },
  {
    icon: '',
    zh: '心理学',
    en: 'Psychology',
  },
  {
    icon: '',
    zh: '市场营销',
    en: 'Marketing',
  },
  {
    icon: '',
    zh: '统计学',
    en: 'Statistical',
  },
  {
    icon: '',
    zh: '化学',
    en: 'Chemistry',
  },
  {
    icon: '',
    zh: '工程学',
    en: 'Engineering',
  },
]
export default function Course() {
  return (
    <section className="course o-section">
      <div className="l-container">
        <div className="l-row">
          <div className="l-col-md-6 order">
            <div className="f5 f6-xs">覆盖专业课程达95%</div>
            <div className="c_text flex flex-wrap justify-between">
              {courseList.map((v, i) => (
                <div
                  key={i}
                >
                  <div
                    className={`w88 ${i % 3 === 1 ? 'mobile-mh-auto' : ''} ${
                      i % 3 === 2 ? 'mobile-right' : ''
                    }`}
                  >
                    <Image
                      alt=""
                      height={48}
                      width={48}
                      src={`/images/pages/index/course/${v.en.toLocaleLowerCase()}.svg`}
                    />
                    <div className="zh">{v.zh}</div>
                    <div className="en">{v.en}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="l-col-md c-r offset-md-1 display_flex_cm ai pl05">
            <div className="f42 fw6 f2-xs mb2-m mb1 mt3-m">
              <span className="green">广泛专业</span>
              课程覆盖
            </div>
            <p className="f4 f15-xs mb0-m mb1">
              海外大学学科不断发展演变，荃程无忧也在持续延展学术辅导的学科范围，目前荃程无忧服务92个专业，覆盖2000+所海外院校的课程，确保精准高效的学术指导。
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
