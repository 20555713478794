/*
 * @Description:首页第一屏
 * @Author: shilin deng
 * @Date: 2021-01-06 09:56:51
 */
/*eslint-disable*/
import React, { useState } from 'react'
import ContactModal from '../modal/contactModal'
import Slider from 'react-slick'
import { Image } from '../../common'

export default function Intro() {
  const [open, setOpen] = useState(false)
  function openModal() {
    setOpen(true)
  }
  const settings = {
    autoplay: true,
    autoplayspeed: 6000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    touchMove: true,
    infinite:true,
    pauseOnHover: true,
    // autoplayspeed: 1000 * 60,
  }
  const list = [
    {
      name: '使命',
      desc: '荃程无忧，让海外学习变简单',
      bg: 'bg1',
    },
    {
      name: '愿景',
      desc: '汇聚顶尖资源，赋能新锐才俊，成就时代精英',
      bg: 'bg2',
    },
  ]
  return (
    <div className="intro_bg">
      <Slider {...settings} className="c-slider c-slider--lt">
        {list.map((v) => (
          <div key={v}>
            <div className={`home-top wd-100 dt ph3-m home_bg_${v.bg}`}>
              <div className="intro tc wd-100 dtc v-mid mw10 center">
                <div className="white mv0 center home-top_border f0">
                  {v.name}
                </div>
                <h1 className="white mv0 center mv2">{v.desc}</h1>
                <a
                  className="try_button top_btn_signup btn btn-large"
                  onClick={openModal}
                >
                  免费咨询
                </a>
                {/* <div className="strategic-partners white mt2 tc">
                  <div className="f4 mb05">战略合作伙伴</div>
                  <div className="dib v-mid pr05 br b--green">
                    <Image
                      width={49}
                      height={21}
                      className="lazy_load_icon new-oriental"
                      src="/images/pages/index/xdf.png"
                      alt="new oriental"
                    />
                  </div>
                  <div className="dib v-mid pl05 pr05 br b--green">
                    <Image
                      className="lazy_load_icon qide"
                      width={71}
                      height={21}
                      alt="qide"
                      src="/images/pages/index/qd_new.png"
                    />
                  </div>
                  <div className="dib v-mid pl05">
                    <Image
                      className="lazy_load_icon lsc-w"
                      width={79}
                      height={21}
                      alt="lsc"
                      src="/images/pages/index/lsc.png"
                    />
                  </div>
                </div> */}
                {open && <ContactModal close={() => setOpen(false)} />}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
