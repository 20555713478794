/*
 * @Description: 培养人才
 * @Author: shilin deng
 * @Date: 2021-11-01 16:10:31
 */

/*eslint-disable*/
import Slider from 'react-slick'

export default function CultivateTalents() {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    // slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 780,
      },
    ],
  }
  const list = [
    {
      icon: 'icon1',
      name: '能够与不同文化背景的人进行开放、合理以及有效的良性互动',
      desc: '了解不同人、地区的文化规范、社会环境以及沟通风格，从而灵活的调整自己的沟通形式以做到言行得体。尊重他人的观点与成果，让所有参与者都能够互相理解。',
    },
    {
      icon: 'icon2',
      name: '能够并愿意为集体福祉和可持续发展采取建设性行动',
      desc: '勇于承担世界公民责任，关注社会可持续发展和人类文明进步；勇于承担责任，在社会与职场致力于建设一个更公正，和平，包容的环境。',
    },
    {
      icon: 'icon3',
      name: '能够尊重并理解不同视角和世界观',
      desc: '理解、欣赏不同的立场观念与文化价值观，对不同人、地区和文化的差异不持偏见，求同存异，学会反思自己，愿意接纳和包容不同的观念和行为。',
    },
    {
      icon: 'icon4',
      name: '能够批判地审视与分析在地区、全球和文化上的重要议题',
      desc: '了解世界历史、地理、经济与社会等方面的基础知识；理解不同国家的政治制度和文化差异；关注国际时事热点；熟悉全球历史、地理、环境、能源、经济发展等重要议题。',
    },
  ]
  const pyList = [
    {
      img: 'py_icon1',
      title: '双师辅助',
      desc: '荃程无忧外籍学业导师+行业大牛职业导师',
    },
    {
      img: 'py_icon2',
      title: '主动规划式教学体系',
      desc: '学前测评+课前预习+每周复习+考前冲刺',
    },
  ]
  return (
    <div className="talents o-section">
      <div className="l-container">
        <div className="u-color-dk-dark f42 f28-xs fw5 mb2 u-text-center-md u-text-left">
          荃程无忧，致力于培养具备全球胜任力的国际化人才
        </div>
        <p className="title-w u-color-dark f4 f5-xs u-text-center-md u-text-left">
          荃程无忧通过学业培优及职业培优的双重培养路径，不仅为每一位留学生提供全方位的学业培优服务，并且帮助留学生在职业道路上有所突破发展，培养具备国际视野，通晓国际规则，能够参与国际事务和国际竞争的国际化人才。
        </p>
        <div className="relative mt3 u-d-block-md u-d-none">
          <div className="relative zIndex9">
            <div className="l-row">
              {list.map((v, i) => (
                <div
                  className={`l-col-md-6 flex u-justify-content-center u-h-100 ${
                    i < 2 ? 'mb7' : ''
                  }`}
                  key={i}
                >
                  <div className={`talents_box ${i > 1 ? 'ml3' : ''}`}>
                    <div>
                      <img
                        alt={v.icon}
                        src={`/images/pages/index/rc_${v.icon}.svg`}
                      />
                    </div>
                    <div className="u-color-dk-dark f4 mv05 fw5">{v.name} </div>
                    <div className="u-color-dark f6">{v.desc} </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="u-text-center rc_bg ">
            <img alt="" width="432px" src="/images/pages/index/rc_bg.svg" />
          </div>
        </div>
      </div>
      <div className="u-d-none-md mt3">
        <Slider {...settings} className="c-slider c-slider--lt">
          {list.map((v, i) => (
            <div key={i} className="l-col-md pl1 pr0">
              <div className={`talents_box`}>
                <div>
                  <img
                    alt={v.icon}
                    src={`/images/pages/index/rc_${v.icon}.svg`}
                  />
                </div>
                <div className="u-color-dk-dark f4 mv05 fw5">{v.name} </div>
                <div className="u-color-dark f6">{v.desc} </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="l-container">
        <div className="l-row mt4">
          <div className="l-col-md-6 l-col-12 mt15">
            <img alt="" src="/images/pages/index/py_bg.png" />
          </div>
          <div className="l-col-md-6 l-col-12 center_center py_order">
            <img alt="" src="/images/pages/index/py_bs.svg" />
            <div className="fw6 u-color-dk-dark f42 mb2-m mt4-m mt2 mb2">
              双师培优体系
            </div>
            <div className="u-color-dark desc_w f4 f5-xs">
              荃程无忧采用外籍学业导师与行业大牛担任职业导师的双师培优体系，通过学前测评+课前预习+每周复习+考前冲刺的主动规划式教学模式，助力每一位留学生不仅成功留学，而且留学成功。
            </div>
          </div>
        </div>
        <div className="l-row mt3-m mt1">
          {pyList.map((v, i) => (
            <div className="l-col-md-6" key={i}>
              <div
                className={`l-row py_box ${
                  i === 0 ? 'mr2-m mh0 mb0-m mb4' : 'ml2-m mh0'
                }`}
              >
                <div className="l-col-md-4 l-col-5 pl0">
                  <img
                    alt=""
                    className="img"
                    src={`/images/pages/index/${v.img}.png`}
                  />
                </div>
                <div className="l-col center_center pl1-m">
                  <div className="u-color-dk-dark fw5 f2 f3-xs">{v.title}</div>
                  <div className="u-color-dark f5 mt1">{v.desc}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt7-m mt5">
          <div className="flex u-justify-content-center">
            <img
              alt=""
              className="mr2-m"
              src="/images/pages/index/py_left.svg"
            />
            <div className="u-color-dk-dark u-text-center">
              <div className="f0 f2-xs">全球超400万大学用户</div>
              <div className="mt05 f3 f6-xs">
                实践证明了荃程无忧培优体系的科学性与优越性
              </div>
            </div>
            <img
              alt=""
              className="ml2-m"
              src="/images/pages/index/py_right.svg"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
